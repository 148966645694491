<template>
  <div v-if="step !== null" class="tour-overlay">
    <div  :style="popoverStyle" class="tour-popover">
      <div :class="arrowClass"></div>
      <v-card-title style="color:#5474FB; padding-bottom: 0; padding-top: 0;" class="justify-center">

        {{ steps[step].title }}</v-card-title>
      <v-card-text v-html="steps[step].description" style="padding-left: 0;padding-right: 0;" >

      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer/>
<!--        <button style="color:#5474FB" @click="nextStep">Aceptar</button>-->
        <v-btn text class="text-none" @click="terminate">Omitir</v-btn>
        <v-btn @click="handleNextStep" class="text-none elevation-0" color="primary" >Aceptar</v-btn>
      </v-card-actions>
    </div>
    <div class="tour-backdrop"></div>
  </div>
</template>


<script>
export default {
  name: 'TourGuide',
  props: {
    steps: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      step: null
    };
  },
  computed: {
    popoverStyle() {
      if (this.step === null) return {};
      const element = document.querySelector(this.steps[this.step].selector);
      const styles = {};

      if (element) {
        const rect = element.getBoundingClientRect();
        switch (this.steps[this.step].position) {
          case 'top':
            styles.top = `${rect.top + window.scrollY - this.$el.querySelector('.tour-popover').offsetHeight - 10}px`;
            styles.left = `${rect.left + window.scrollX + rect.width / 2 - 20}px`;
            break;
          case 'right':
            styles.top = `${rect.top + window.scrollY + rect.height / 2 - 20}px`;
            styles.left = `${rect.left + window.scrollX + rect.width + 10}px`;
            break;
          case 'bottom':
            styles.top = `${rect.top + window.scrollY + rect.height + 10}px`;
            styles.left = `${rect.left + window.scrollX + rect.width / 2 - 20}px`;
            break;
          case 'left':
            styles.top = `${rect.top + window.scrollY + rect.height / 2 - 20}px`;
            styles.left = `${rect.left + window.scrollX - this.$el.querySelector('.tour-popover').offsetWidth - 10}px`;
            break;
          default:
            styles.top = `${rect.top + window.scrollY + rect.height + 10}px`;
            styles.left = `${rect.left + window.scrollX}px`;
            break;
        }
      }

      // Aplicar propiedades de estilo adicionales si existen
      if (this.steps[this.step].right) {
        styles.right = '50px!important'
      }
      if (this.steps[this.step].left) {
        styles.left = this.steps[this.step].left;
      }
      if (this.steps[this.step].top) {
        styles.top = this.steps[this.step].top;
      }
      if (this.steps[this.step].bottom) {
        styles.bottom = this.steps[this.step].bottom;
      }

      return styles;
    },
    arrowClass() {
      switch (this.steps[this.step].position) {
        case 'top':
          return 'tour-arrow-top';
        case 'right':
          return 'tour-arrow-right';
        case 'bottom':
          return 'tour-arrow-bottom';
        case 'left':
          return 'tour-arrow-left';
        default:
          return 'tour-arrow-bottom';
      }
    }
  },

  methods: {
    start() {
      this.step = 0;
    },
    handleNextStep() {
      this.executeAction(); // Llama la acción personalizada
      this.nextStep(); // Avanza al siguiente paso
    },
    terminate(){
      this.step = null;
      let tour = JSON.parse(localStorage.getItem('tour'));
      if (tour) {
        tour = { one: true, two: true, three: true };
        localStorage.setItem('tour', JSON.stringify(tour));
      }

    },
    nextStep() {
      if (this.step < this.steps.length - 1) {
        this.step++;
      } else {
        this.step = null; // Termina el tour
      }
    },
    executeAction() {
      if (this.steps[this.step].action === 'drawer') {
        console.log('Abriendo drwaer')
        this.$emit('abrirdrawer')
        //this.steps[this.step].action(); // Ejecuta la acción si existe
      }else if (this.steps[this.step].action === 'huella-de-carbono') {
        this.$emit('huella-de-carbono')
      }else if (this.steps[this.step].action === 'scrollone') {
        this.$emit('scrollone')
      }else if (this.steps[this.step].action === 'goHome') {
        this.$emit('goHome')
      }
    }
  }
};
</script>

<style scoped>
.tour-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: none;
}

.tour-popover {
  position: absolute;
  background: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  pointer-events: auto;
  max-width: 500px;
}

.tour-popover .tour-arrow-top {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
  bottom: -10px;
  left: 20px;
  transform: translateX(-50%);
}

.tour-popover .tour-arrow-bottom {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  top: -10px;
  left: 20px;
  transform: translateX(-50%);
}

.tour-popover .tour-arrow-left {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
  right: -10px;
  top: 20px;
  transform: translateX(-50%);
}

.tour-popover .tour-arrow-right {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
  left: -5px;
  top: 20px;
  transform: translateX(-50%);
}
.tour-popover .aotrolugar {
  position: absolute;
  background: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  pointer-events: auto;
  max-width: 200px;
  transform: translateX(-50%);
}

.tour-popover h3 {
  margin: 0 0 10px 0;
}

.tour-popover button {
  /*margin-top: 10px;*/
}

.tour-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
</style>
